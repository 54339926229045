import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { HiXMark } from "react-icons/hi2";

import BuyButton from "./buyButton";

export default function Pricing() {
  const [selected, setSelected] = useState("M");

  return (
    <section
      className="w-full text-white bg-black px-2 lg:px-8 py-12 lg:py-24 relative overflow-hidden"
      id="pricing"
    >
      <Heading selected={selected} setSelected={setSelected} />
      <PriceCards selected={selected} />
      <TopLeftCircle />
      <BottomRightCircle />
    </section>
  );
}

const SELECTED_STYLES =
  "text-black font-medium rounded-lg py-3 w-28 relative bg-white";
const DESELECTED_STYLES =
  "font-medium rounded-lg py-3 w-28 hover:bg-gray-700 transition-colors relative";

const Heading = ({ selected, setSelected }) => {
  return (
    <div className="mb-12 relative z-10">
      <h2 className="font-semibold text-5xl lg:text-7xl text-center mb-6">
        Nos différents Tarifs
      </h2>
      <p className="text-center mx-auto max-w-lg mb-8">
        Découvrez nos options Nitro conçues pour répondre à vos besoins
        spécifiques. Que vous cherchiez à personnaliser vos interactions avec
        des emojis et des stickers ou plus, nous avons le plan qui vous
        convient.
      </p>

      <div className="flex items-center justify-center gap-3">
        <button
          onClick={() => setSelected("M")}
          className={selected === "M" ? SELECTED_STYLES : DESELECTED_STYLES}
        >
          Mensuel
          {selected === "M" && <BackgroundShift />}
        </button>
        <div className="relative">
          <button
            onClick={() => setSelected("A")}
            className={selected === "A" ? SELECTED_STYLES : DESELECTED_STYLES}
          >
            Annuel
            {selected === "A" && <BackgroundShift />}
          </button>
          <CTAArrow />
        </div>
      </div>
    </div>
  );
};

const BackgroundShift = () => (
  <motion.span
    layoutId="bg-shift"
    className="absolute inset-0 bg-white rounded-lg -z-10"
  />
);

const CTAArrow = () => (
  <div className="absolute md:-right-[100px] -right-[80px] top-2 sm:top-0">
    <motion.svg
      width="95"
      height="62"
      viewBox="0 0 95 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="scale-50 sm:scale-75"
      initial={{ scale: 0.7, rotate: 5 }}
      animate={{ scale: 0.75, rotate: 0 }}
      transition={{
        repeat: Infinity,
        repeatType: "mirror",
        duration: 1,
        ease: "easeOut",
      }}
    >
      <defs>
        <linearGradient id="rainbowGradient" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor="#8f14e6">
            <animate
              attributeName="stop-color"
              values="#8f14e6; #e614dc; #e61453; #e68414; #e6e614; #e68414; #e61453; #e614dc; #8f14e6"
              dur="8s"
              repeatCount="indefinite"
            />
          </stop>
          <stop offset="100%" stopColor="#8f14e6">
            <animate
              attributeName="stop-color"
              values="#8f14e6; #e614dc; #e61453; #e68414; #e6e614; #e68414; #e61453; #e614dc; #8f14e6"
              dur="8s"
              repeatCount="indefinite"
            />
          </stop>
        </linearGradient>
      </defs>

      <path
        d="M14.7705 15.8619C33.2146 15.2843 72.0772 22.1597 79.9754 54.2825"
        stroke="url(#rainbowGradient)"
        strokeWidth="3"
      />
      <path
        d="M17.7987 7.81217C18.0393 11.5987 16.4421 15.8467 15.5055 19.282C15.2179 20.3369 14.9203 21.3791 14.5871 22.4078C14.4728 22.7608 14.074 22.8153 13.9187 23.136C13.5641 23.8683 12.0906 22.7958 11.7114 22.5416C8.63713 20.4812 5.49156 18.3863 2.58664 15.9321C1.05261 14.6361 2.32549 14.1125 3.42136 13.0646C4.37585 12.152 5.13317 11.3811 6.22467 10.7447C8.97946 9.13838 12.7454 8.32946 15.8379 8.01289"
        stroke="url(#rainbowGradient)"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </motion.svg>

    <div>
      <div className="relative overflow-hidden block text-xs w-fit text-white shadow px-1.5 py-0.5 rounded -mt-1 ml-8 -rotate-2 font-light italic">
        <span className="flex items-center justify-center relative z-10">
          Save €€€
        </span>
        <motion.div
          initial={{ left: 0 }}
          animate={{ left: "-300%" }}
          transition={{
            repeat: Infinity,
            repeatType: "mirror",
            duration: 4,
            ease: "linear",
          }}
          className="bg-[linear-gradient(to_right,#8f14e6,#e614dc,#e61453,#e68414,#e6e614)] absolute inset-0 w-[400%] h-full z-0"
        ></motion.div>
      </div>
    </div>
  </div>
);

const PriceCards = ({ selected }) => (
  <div className="flex flex-col lg:flex-row justify-center gap-8 lg:gap-4 w-full max-w-6xl mx-auto relative z-10">
    {/* Nitro Classic */}
    <div className="w-[350px] lg:w-[400px] bg-gray-800 p-6 border-[1px] border-gray-700 rounded-xl text-white flex flex-col justify-between mx-auto">
      <div>
        <p className="text-2xl font-bold mb-2">Nitro Classic</p>
        <AnimatePresence mode="wait">
          {selected === "M" ? (
            <motion.p
              key="monthly1"
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 50, opacity: 0 }}
              transition={{ ease: "linear", duration: 0.25 }}
              className="text-lg mb-6"
            >
              Prix officiel 3,99€
            </motion.p>
          ) : (
            <motion.p
              key="monthly2"
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 50, opacity: 0 }}
              transition={{ ease: "linear", duration: 0.25 }}
              className="text-lg mb-6"
            >
              Prix officiel 39,99€
            </motion.p>
          )}
        </AnimatePresence>
        <div className="overflow-hidden mb-8">
          <AnimatePresence mode="wait">
            {selected === "M" ? (
              <motion.p
                key="monthly1"
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 50, opacity: 0 }}
                transition={{ ease: "linear", duration: 0.25 }}
                className="text-6xl font-bold rainbow-text"
              >
                <span>1€50</span>
              </motion.p>
            ) : (
              <motion.p
                key="monthly2"
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 50, opacity: 0 }}
                transition={{ ease: "linear", duration: 0.25 }}
                className="text-6xl font-bold rainbow-text"
              >
                <span>10€</span>
              </motion.p>
            )}
          </AnimatePresence>
        </div>

        <div className="flex items-center gap-2 mb-2">
          <IoIosCheckmarkCircleOutline className="text-2xl" />
          <span className="text-base text-sm">Émojis personnalisés</span>
        </div>
        <div className="flex items-center gap-2 mb-2">
          <IoIosCheckmarkCircleOutline className="text-2xl" />
          <span className="text-base text-sm">Stickers personnalisés</span>
        </div>
        <div className="flex items-center gap-2 mb-2">
          <IoIosCheckmarkCircleOutline className="text-2xl" />
          <span className="text-base text-sm">Super Reactions</span>
        </div>
        <div className="flex items-center gap-2 mb-2">
          <HiXMark className="text-2xl" />
          <span className="text-base text-sm">2 boosts</span>
        </div>
        <div className="flex items-center gap-2 mb-2">
          <HiXMark className="text-2xl" />
          <span className="text-base text-sm">Avatar animé</span>
        </div>
        <div className="flex items-center gap-2 mb-2">
          <HiXMark className="text-2xl" />
          <span className="text-base text-sm">Personnalise ton profil</span>
        </div>
        <div className="flex items-center gap-2 mb-2">
          <HiXMark className="text-2xl" />
          <span className="text-base text-sm">Personnalise ton thème</span>
        </div>
        <div className="flex items-center gap-2 mb-2">
          <IoIosCheckmarkCircleOutline className="text-2xl" />
          <span className="text-base text-sm">Badge Nitro</span>
        </div>
      </div>

      {/* Ajouter ici le message Rupture de Stock */}
      <div className="text-center rainbow-text font-bold text-lg">
        Rupture de stock
      </div>
    </div>

    {/* Nitro */}
    <div className="w-[350px] lg:w-[400px] bg-gray-800 p-6 border-[1px] border-gray-700 rounded-xl text-white flex flex-col justify-between mx-auto">
      <div>
        <p className="text-2xl font-bold mb-2">Nitro</p>
        <AnimatePresence mode="wait">
          {selected === "M" ? (
            <motion.p
              key="monthly1"
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 50, opacity: 0 }}
              transition={{ ease: "linear", duration: 0.25 }}
              className="text-lg mb-6"
            >
              Prix officiel 9,99€
            </motion.p>
          ) : (
            <motion.p
              key="monthly2"
              initial={{ y: -50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 50, opacity: 0 }}
              transition={{ ease: "linear", duration: 0.25 }}
              className="text-lg mb-6"
            >
              Prix officiel 99,99€
            </motion.p>
          )}
        </AnimatePresence>
        <div className="overflow-hidden mb-8">
          <AnimatePresence mode="wait">
            {selected === "M" ? (
              <motion.p
                key="monthly1"
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 50, opacity: 0 }}
                transition={{ ease: "linear", duration: 0.25 }}
                className="text-6xl font-bold rainbow-text"
              >
                <span>5€</span>
              </motion.p>
            ) : (
              <motion.p
                key="monthly2"
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 50, opacity: 0 }}
                transition={{ ease: "linear", duration: 0.25 }}
                className="text-6xl font-bold rainbow-text"
              >
                <span>40€</span>
              </motion.p>
            )}
          </AnimatePresence>
        </div>

        <div className="flex items-center gap-2 mb-2">
          <IoIosCheckmarkCircleOutline className="text-2xl" />
          <span className="text-base text-sm">Émojis personnalisés</span>
        </div>
        <div className="flex items-center gap-2 mb-2">
          <IoIosCheckmarkCircleOutline className="text-2xl" />
          <span className="text-base text-sm">Stickers personnalisés</span>
        </div>
        <div className="flex items-center gap-2 mb-2">
          <IoIosCheckmarkCircleOutline className="text-2xl" />
          <span className="text-base text-sm">Super Reactions</span>
        </div>
        <div className="flex items-center gap-2 mb-2">
          <IoIosCheckmarkCircleOutline className="text-2xl" />
          <span className="text-base text-sm">2 boosts</span>
        </div>
        <div className="flex items-center gap-2 mb-2">
          <IoIosCheckmarkCircleOutline className="text-2xl" />
          <span className="text-base text-sm">Avatar animé</span>
        </div>
        <div className="flex items-center gap-2 mb-2">
          <IoIosCheckmarkCircleOutline className="text-2xl" />
          <span className="text-base text-sm">Personnalise ton profil</span>
        </div>
        <div className="flex items-center gap-2 mb-2">
          <IoIosCheckmarkCircleOutline className="text-2xl" />
          <span className="text-base text-sm">Personnalise ton thème</span>
        </div>
        <div className="flex items-center gap-2 mb-2">
          <IoIosCheckmarkCircleOutline className="text-2xl" />
          <span className="text-base text-sm">Badge Nitro</span>
        </div>
      </div>

      <BuyButton />
    </div>
  </div>
);

const TopLeftCircle = () => (
  <div className="absolute top-[-100px] left-[-100px] z-0">
    <motion.div
      initial={{ opacity: 0.3 }}
      animate={{ opacity: [0.3, 0.5, 0.3] }}
      transition={{
        repeat: Infinity,
        repeatType: "reverse",
        duration: 3,
        ease: "easeOut",
      }}
      className="bg-gradient-to-br from-gray-600 to-gray-900 rounded-full h-[250px] w-[250px]"
    />
  </div>
);

const BottomRightCircle = () => (
  <div className="absolute bottom-[-150px] right-[-150px] z-0">
    <motion.div
      initial={{ opacity: 0.2 }}
      animate={{ opacity: [0.2, 0.5, 0.2] }}
      transition={{
        repeat: Infinity,
        repeatType: "reverse",
        duration: 3,
        ease: "easeOut",
      }}
      className="bg-gradient-to-br from-gray-600 to-gray-900 rounded-full h-[400px] w-[400px]"
    />
  </div>
);
